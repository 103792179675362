import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import Layout from "../../components/Layout";
import { getDiscountTerms } from '../../reducers/Terms/actions'
import { connect } from 'react-redux'
import { urlDescription } from '../../utils/pageDescription'
import SideNav from "../../components/SideNav/SideNav";
import "./styles.scss";

const DiscountTerms = ({ discounts, getDiscountTerms }) => {
  const [currentTerm, setCurrentTerm] = useState('');

  useEffect(() => {
    getDiscountTerms({ type: 'discount' })
  }, [getDiscountTerms])

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="terms-wrapper">
        <div className="terms-title-wrapper">
          <div className="discount-terms-page-title mt-5">
            <h1 className="font-weight-bold">Условия на промоциите</h1>
          </div>
        </div>
        <div className="terms-container">
          <Row className="mt-5">
            <Col className='mb-5 sidenav-menu-col' xs={12} md={4} lg={4}>
              {discounts && discounts[0].sections
                ? <SideNav
                  terms={discounts[0]}
                  setCurrentTerm={setCurrentTerm}
                  termTitle={currentTerm}
                  discounts />
                : null
              }
            </Col>
            <Col className="terms-of-use-col" lg={8} md={8}>
              <div className="discount-terms-title w-75">
                <h2
                  className="mt-2"
                  id="first-section">
                  {discounts && discounts[0].title}
                </h2>
              </div>
              <div className="discount-terms mt-5">
                {
                  discounts &&
                  discounts[0].sections.map(term => (
                    <div
                      name={`${term.id}`}
                      className="mb-5"
                      key={term.id}
                    >
                      <h3
                        className="h4"
                        id={term.id}>
                        {term.title}
                      </h3>
                      <p
                        dangerouslySetInnerHTML={{ __html: term.description }}
                        className='mt-3'
                      />
                    </div>
                  ))
                }
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => ({
  discounts: state.terms.discount
});

const mapDispatchToProps = {
  getDiscountTerms
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountTerms);
